.container {
  gap: 4rem;
}
.links-block {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 1rem;
  &__item {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 20px;
    &_link {
      border-radius: 0.5rem;
      &:hover {
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
      }
    }
  }
}
@media (max-width: 960px) {
  .container {
    gap: 0;
  }
  .links-block {
    grid-template-columns: 1fr;
    &__item_divider {
      display: none;
    }
  }
}
