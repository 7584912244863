.header{
    position: relative;
    display: flex;
    justify-content: space-between;
    margin-bottom: 1em;
    flex-wrap: wrap;
    padding: 1rem 1rem 1rem 1rem ;
    &__logo{
        object-fit:contain
    }
}
@media (max-width: 780px) {
    .header__right-image{
        display: none;
    }
}