.wrapper {
  min-height: 100vh;
  background-image: url("./assets/images/backgronund.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
  max-width: 100vw;
}
.mailbox-image {
  object-fit: cover;
  height: 200px;
  position: absolute;
  bottom: 0px;
  right:  -22px;
}
@media (max-width:1080px){
  .mailbox-image{
    display: none;
  }
}