/* Basic reset stuff */

* {
  margin: 0;
  padding: 0;
}

*,
::before,
::after {
  box-sizing: border-box;
}

html:focus-within {
  scroll-behavior: smooth;
}

body {
  min-height: 100vh;
  text-rendering: optimizeSpeed;
  line-height: 1.2;
  max-width: 100vw;
  overflow-x: hidden;
}

ul[role="list"],
ol[role="list"] {
  list-style: none;
}

a:not([class]) {
  text-decoration-skip-ink: auto;
}

/*  
    Optional
*/

img,
picture {
  max-width: 100%;
  display: block;
}

input,
button,
textarea,
select {
  font: inherit;
}

/*  
    Remove all animations, transitions and smooth scroll 
    for people that prefer to see them not
*/

@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }

  *,
  ::before,
  ::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}